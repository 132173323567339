import Vue from 'vue'
import App from './App.vue'
import VueObserveVisibility from 'vue-observe-visibility'
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getDatabase } from "firebase/database"

const firebaseConfig = {
  apiKey: "AIzaSyD1ADP1fVFKjeYg8obMVtZhkx5xFUTF_PM",
  authDomain: "jims-movies.firebaseapp.com",
  projectId: "jims-movies",
  storageBucket: "jims-movies.appspot.com",
  databaseURL: "https://jims-movies-default-rtdb.firebaseio.com",
  messagingSenderId: "446977207923",
  appId: "1:446977207923:web:4fc901d924b5a5a8cb8406",
  measurementId: "G-1LF8N5M2YT"
}

const app = initializeApp(firebaseConfig)
export const db = getDatabase(app)
getAnalytics(app)

Vue.config.productionTip = false
Vue.use(VueObserveVisibility)

new Vue({
  render: h => h(App),
}).$mount('#app')
